<template>
  <div class="communityTable">
    <div class="mergeBtn">
      <v-button
        v-if="isRoom && multipleSelection.length == 2"
        text="合并"
        @click="mergeRow"
      ></v-button>
      <v-button
        type="info"
        @click="mergeRowInfo"
        v-if="isRoom && multipleSelection.length != 2"
        text="合并"
      ></v-button>
    </div>
    <div class="table">
      <el-table
        v-loading="isLoading"
        element-loading-text="数据正在加载中..."
        ref="multipleTable"
        fit
        :height="'100%'"
        :row-key="rowKey"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="isRoom"
          type="selection"
          :selectable="selectable"
          :reserve-selection="reserveSelection"
          width="35"
        ></el-table-column>
        <el-table-column align="center" label="手机号" :min-width="125">
          <template slot-scope="scope">
            <div class="item-l" v-if="judgeIsEdit(scope.row, 'phone')">
              {{ scope.row.phone }}
            </div>
            <div class="item-r" v-else>
              <v-input v-model="inputParam.phone" />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="真实姓名" :min-width="110">
          <template slot-scope="scope">
            <div class="name">
              <div class="item-l" v-if="judgeIsEdit(scope.row, 'realName')">
                {{ scope.row.realName }}
              </div>
              <div class="item-r" v-else>
                <v-input v-model="inputParam.realName" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="性别" :min-width="80">
          <template slot-scope="scope">
            <div class="item-l" v-if="judgeIsEdit(scope.row, 'sex')">
              {{ scope.row.sex ? oSexsMap[scope.row.sex] : "" }}
            </div>
            <div class="item-r" v-else>
              <v-select :options="oSexs" v-model="inputParam.sex" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="房号" :min-width="120">
          <template slot-scope="scope">{{ scope.row.roomName }}</template>
        </el-table-column>
        <el-table-column align="center" label="身份证类型" :min-width="130">
          <template slot-scope="scope">
            <div class="cardType">
              <div class="item-l" v-if="judgeIsEdit(scope.row, 'cardType')">
                {{
                  scope.row.cardType ? cardTypeListMap[scope.row.cardType] : ""
                }}
              </div>
              <div class="item-r" v-else>
                <v-select
                  :options="cardTypeList"
                  v-model="inputParam.cardType"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="身份证号码" :min-width="140">
          <template slot-scope="scope">
            <div class="item-l" v-if="judgeIsEdit(scope.row, 'cardNum')">
              {{ scope.row.cardNum }}
            </div>
            <div class="item-r" v-else>
              <v-input v-model="inputParam.cardNum" />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="用户状态">
          <template slot-scope="scope">{{
            scope.row.status ? userStatusMap[scope.row.status] : ""
          }}</template>
        </el-table-column>
        <el-table-column label="角色标签" :min-width="120">
          <template slot-scope="scope">
            <div class="label" v-if="scope.row.userLabelList">
              <div
                class="label-l"
                v-if="judgeIsEdit(scope.row, 'userLabelList')"
              >
                <span
                  v-for="(item, index) in scope.row.userLabelList"
                  :key="index"
                  >{{ item.labelName }}</span
                >
              </div>
              <div class="label-l" v-else>
                <span
                  v-for="(item, index) in inputParam.userLabelList"
                  :key="index"
                  >{{ item.labelName }}</span
                >
                <v-button
                  text="编辑标签"
                  :width="70"
                  @click="toEditSign(scope.row)"
                ></v-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="120">
          <template slot-scope="scope">
            <div class="opera">
              <v-button
                text="编辑"
                v-if="isEditTable(scope.row)"
                @click="edit(scope.row)"
              ></v-button>
              <v-button
                text="保存"
                v-else
                :width="50"
                @click="save(scope.row)"
              ></v-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <v-pagination
      :total="pages.total"
      :curPage="pages.curPage"
      :pageSize="pages.pageSize"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></v-pagination>
    <div class="signDialog" v-if="isShowSign">
      <div class="box">
        <div class="title">编辑标签</div>
        <div class="signBox">
          <v-cascader
            ref="cascader"
            v-loading="businessLabelLoading"
            placeholder="请选择"
            label="标签"
            @change="change"
            :clearable="false"
            v-model="userLabelIds"
            :options="businessSectionOps"
            :props="businessProps"
          />
        </div>
        <div class="footer">
          <v-button text="取消" @click="editSign"></v-button>
          <v-button text="确定" @click="saveSign"></v-button>
        </div>
      </div>
    </div>
    <div class="mergeDialog" v-if="isMergeDialog">
      <div class="mergeDialogBox">
        <div class="mergeDialogBox-t">
          <div class="mergeDialogBox-l">
            <draggable
              class="list-group"
              filter=".item"
              v-model="mergeItem"
              @change="mergeChange"
              @start="mergeStart"
              group="people"
            >
              <div
                :class="['item', { isActive: item.isActive }]"
                v-for="(item, index) in mergeItem"
                :key="index"
                @click="selectMergeItem(item)"
              >
                {{ item.value }}
              </div>
              <!-- <div class="item">{{ mergeItem.phone }}</div>
              <div class="item">{{ mergeItem.realName }}</div>
              <div class="item">{{ oSexsMap[mergeItem.sex] }}</div>
              <div class="item">{{ cardTypeListMap[mergeItem.cardType] }}</div>
              <div class="item">{{ mergeItem.cardNum }}</div> -->
            </draggable>
          </div>
          <div class="img"><img src="./img/rightArrow.png" alt="" /></div>
          <div class="mergeDialogBox-c">
            <draggable
              filter=".item"
              class="list-group"
              v-model="resItem"
              group="people"
            >
              <div
                :class="['item', item.key]"
                v-for="(item, index) in resItem"
                :key="index"
              >
                {{ item.value }}
              </div>
            </draggable>
          </div>
          <div class="img"><img src="./img/leftArrow.png" alt="" /></div>
          <div class="mergeDialogBox-r">
            <draggable
              class="list-group"
              filter=".item"
              @change="toMergeChange"
              @start="mergeStart"
              v-model="toMergeItem"
              group="people"
            >
              <div
                v-for="(item, index) in toMergeItem"
                :key="index"
                :class="['item', { isActive: item.isActive }]"
                @click="selectToMergeItem(item)"
              >
                {{ item.value }}
              </div>
              <!-- <div class="item">{{ toMergeItem.phone }}</div>
              <div class="item">{{ toMergeItem.realName }}</div>
              <div class="item">{{ oSexsMap[toMergeItem.sex] }}</div>
              <div class="item">
                {{ cardTypeListMap[toMergeItem.cardType] }}
              </div>
              <div class="item">{{ toMergeItem.cardNum }}</div> -->
            </draggable>
          </div>
        </div>
        <div class="mergeDialogBox-b">
          <v-button text="取消" @click="editMerge"></v-button>
          <v-button text="确定" @click="saveMerge"></v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  oSexs,
  userStatusMap,
  userStatus,
  oSexsMap,
  cardTypeList,
  cardTypeListMap,
} from "../map";
import {
  getLabelTreeListURL,
  mergeContrastUserURL,
  modifyContrastUserURL,
} from "../api";
import Vue from "vue";
import { Table, TableColumn } from "element-ui";
import { parseGeoJSON } from "echarts";
Vue.use(Table);
Vue.use(TableColumn);
export default {
  components: {
    draggable,
  },
  props: {
    tableUrl: {
      type: String,
      default: "",
    },
    // 当内容过长被隐藏时显示 tooltip
    showOverflowTooltip: {
      type: Boolean,
      default: false,
    },
    // 是否显示操作列
    isOperateColumn: {
      type: Boolean,
      default: true,
    },
    isIndex: {
      type: Boolean,
      default: false,
    },
    operateColumnWidth: Number,
    operateColumnLabel: {
      type: String,
      default: "操作",
    },
    isManySelect: {
      type: Boolean,
      default: false,
    },
    isRoom: {
      type: Boolean,
      default: false,
    },
    // 按钮宽度
    width: Number,
    type: {
      type: String,
      default: "primary",
    },
    value: {
      type: String,
      default: "",
    },

    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    searchParam: {
      type: Object,
      default: function () {
        return {};
      },
    },
    newParam: {
      type: Object,
      default: function () {
        return {};
      },
    },
    extraParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
    postData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    reserveSelection: {
      type: Boolean,
      default: true,
    },
    rowKey: {
      type: [String, Function],
      default() {
        return (row) => {
          return row.id;
        };
      },
    },
  },
  data() {
    return {
      isMergeDialog: false,
      isShowSign: false,
      businessLabelLoading: false,
      oSexs,
      cardTypeList,
      isEdit: true,
      userStatusMap,
      oSexsMap,
      cardTypeListMap,
      oParam: {},
      pages: {
        total: 0,
        curPage: 1,
        pageSize: 10,
      },
      key: {
        realName: "",
        sex: "",
        cardType: "",
        cardNum: "",
      },
      mergeItem: [],
      resItem: [],
      toMergeItem: [],
      inputParam: {
        status: null,
        id: null,
        realName: null,
        phone: null,
        cardType: null,
        cardNum: null,
        sex: null,
        tags: null,
        userLabelList: null,
      },
      isLoading: false,
      tableData: [],
      businessSectionOps: [],
      multipleSelection: [],
      businessLabelPathIds: [], // 添加treeIds的数据
      userLabelIds: [],
      businessProps: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
        disabled: "disabled",
      },
      labelsList: [],
      middleItem: [],
    };
  },
  watch: {},
  created() {
    this.getLabelTreeList("", 1, {
      loading: "businessLabelLoading",
      sectionOps: "businessSectionOps",
      pathIds: "businessLabelPathIds",
    });
  },
  async mounted() {
    this.beforeList ? await this.beforeList() : "";
    if (this.tableUrl) {
      await this.getList();
    }
  },
  methods: {
    selectToMergeItem(item) {
      item.isActive = true;
      let keyList = this.resItem.map((ele) => ele.key);
      if (keyList.indexOf(item.key) != -1) {
        for (var i = 0; i < this.resItem.length; i++) {
          if (item.key == this.resItem[i].key) {
            this.$set(this.resItem, i, item);
          }
        }
        for (var j = 0; j < this.mergeItem.length; j++) {
          if (item.key == this.mergeItem[j].key) {
            this.$set(this.mergeItem[j], "isActive", false);
          }
        }
      } else {
        this.resItem.push(item);
      }
    },
    selectMergeItem(item) {
      item.isActive = true;
      let keyList = this.resItem.map((ele) => ele.key);
      if (keyList.indexOf(item.key) != -1) {
        for (var i = 0; i < this.resItem.length; i++) {
          if (item.key == this.resItem[i].key) {
            this.$set(this.resItem, i, item);
          }
        }
        for (var j = 0; j < this.toMergeItem.length; j++) {
          if (item.key == this.toMergeItem[j].key) {
            this.$set(this.toMergeItem[j], "isActive", false);
          }
        }
      } else {
        this.resItem.push(item);
      }
    },
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    countOccurrences(arr, elem) {
      return arr.reduce((count, current) => {
        return current === elem ? count + 1 : count;
      }, 0);
    },
    mergeStart() {},
    mergeChange(val) {
      console.log(val, this.resItem, "9999___+++++");
      // let oKey = val.removed.element.key;
      // let oValue = val.removed.element.value;
      // let keyList = this.resItem.map((ele) => ele.key);
      // let count = this.countOccurrences(keyList, oKey);
      // if (count != 1) {
      //   for (let i = 0; i < this.resItem.length; i++) {
      //     if (this.resItem[i].value != oValue && this.resItem[i].key == oKey) {
      //       this.resItem.splice(i, 1);
      //       this.toMergeItem.push(this.resItem[i]);
      //       break;
      //     }
      //   }
      // }
    },
    toMergeChange(val) {
      console.log(val, this.resItem, "9999___+++++");
      // let oKey = val.removed.element.key;
      // let oValue = val.removed.element.value;
      // let keyList = this.resItem.map((ele) => ele.key);
      // let count = this.countOccurrences(keyList, oKey);
      // if (count != 1) {
      //   for (let i = 0; i < this.resItem.length; i++) {
      //     if (this.resItem[i].value != oValue && this.resItem[i].key == oKey) {
      //       this.mergeItem.push(this.resItem[i]);
      //       this.resItem.splice(i, 1);
      //       break;
      //     }
      //   }
      // }
    },
    editMerge() {
      this.isMergeDialog = false;
    },
    async saveMerge() {
      console.log(this.resItem, "000");
      let oData = {
        cardNum: "",
        cardType: "",
        importId: "",
        phone: "",
        realName: "",
        registerId: "",
        sex: "",
      };
      for (var i in oData) {
        this.resItem.forEach((ele) => {
          if (ele.key == i) {
            oData[i] = ele.text;
          }
        });
      }
      if (!oData.realName) {
        this.$message.info(
          "姓名还未选择，就此合并将导致此项被清空，请确认操作无误"
        );
        return;
      }
      if (!oData.sex && oData.sex !== 0) {
        this.$message.info(
          "性别还未选择，就此合并将导致此项被清空，请确认操作无误"
        );
        return;
      }
      if (!oData.cardType) {
        this.$message.info(
          "身份类型还未选择，就此合并将导致此项被清空，请确认操作无误"
        );
        return;
      }
      if (!oData.cardNum) {
        this.$message.info(
          "身份证号还未选择，就此合并将导致此项被清空，请确认操作无误"
        );
        return;
      }

      this.multipleSelection.forEach((ele) => {
        if (ele.status == 2) {
          oData.phone = ele.phone;
          oData.registerId = ele.id;
        }
        if (ele.status == 1) {
          oData.importId = ele.id;
        }
      });
      let res = await this.$axios({
        url: `${mergeContrastUserURL}`,
        method: "post",
        data: oData,
      });
      if (res.code == 200) {
        this.isMergeDialog = false;
        this.init();
        this.toggleSelection();
      }
    },
    mergeRowInfo() {
      this.$message.info("请选择2个用户进行合并");
    },
    async mergeRow() {
      let keyList = this.multipleSelection.map((ele) => ele.status);
      if (keyList.length == 2) {
        if (keyList.indexOf(2) != "-1" && keyList.indexOf(1) != "-1") {
          this.isMergeDialog = true;
          this.mergeItem = [];
          this.resItem = [];
          this.toMergeItem = [];
          for (var i in this.key) {
            let obj = {
              key: i,
              value: this.multipleSelection[0][i],
              text: this.multipleSelection[0][i],
            };
            if (i == "sex" && this.multipleSelection[0][i]) {
              obj = {
                key: i,
                text: this.multipleSelection[0][i],
                value: oSexsMap[this.multipleSelection[0][i]],
              };
            } else if (i == "cardType" && this.multipleSelection[0][i]) {
              obj = {
                key: i,
                text: this.multipleSelection[0][i],
                value: cardTypeListMap[this.multipleSelection[0][i]],
              };
            }
            let obj1 = {
              key: i,
              text: this.multipleSelection[1][i],
              value: this.multipleSelection[1][i],
            };
            if (i == "sex" && this.multipleSelection[1][i]) {
              obj1 = {
                key: i,
                text: this.multipleSelection[1][i],
                value: oSexsMap[this.multipleSelection[1][i]],
              };
            } else if (i == "cardType" && this.multipleSelection[1][i]) {
              obj1 = {
                key: i,
                text: this.multipleSelection[1][i],
                value: cardTypeListMap[this.multipleSelection[1][i]],
              };
            }
            if (this.multipleSelection[1][i]) {
              this.toMergeItem.push(obj1);
            }
            if (this.multipleSelection[0][i]) {
              this.mergeItem.push(obj);
            }
          }
        } else if (keyList.indexOf(3) != "-1" && keyList.indexOf(1) != "-1") {
          //已实名认证
          let oData = {
            importId: "",
            registerId: "",
          };
          this.multipleSelection.forEach((ele) => {
            if (ele.status == 3) {
              oData.registerId = ele.id;
            }
            if (ele.status == 1) {
              oData.importId = ele.id;
            }
          });
          let res = await this.$axios({
            url: `${mergeContrastUserURL}`,
            method: "post",
            data: oData,
          });
          if (res.code == 200) {
            this.init();
            this.toggleSelection();
          }
        } else {
          this.$message.info("选中的两个用户不符合合并规则");
        }
      }
    },
    selectable() {
      return true;
    },
    init() {
      this.pages.curPage = 1;
      this.pages.pageSize = 10;
      this.getList();
    },
    change(value) {
      const checkedNodes = this.$refs.cascader.getCheckedNodes();
      const labels = checkedNodes.map((node) => {
        let obj = {
          id: node.value,
          text: node.label,
        };
        return obj;
      });
      this.labelsList = labels;
    },
    editSign() {
      this.isShowSign = false;
    },
    saveSign() {
      this.inputParam.userLabelList = [];
      let labels = this.userLabelIds.map((a) => a[a.length - 1]);
      this.labelsList.forEach((item) => {
        if (labels.includes(item.id)) {
          this.inputParam.userLabelList.push({
            labelId: item.id,
            labelName: item.text,
          });
        }
      });
      this.isShowSign = false;
    },
    // 获取标签选项树
    async getLabelTreeList(userId, labelType, prop) {
      this[prop.loading] = true;
      let res = await this.$axios.get(getLabelTreeListURL, {
        params: {
          labelType,
          userId,
          bizLabelId: "", // 商户标签id，商户标签编辑页面的场合必传
        },
      });
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data, prop);
        this[prop.sectionOps] = res.data;
      }
      this[prop.loading] = false;
      this.businessSectionOps.forEach((ele) => {
        if (!ele.canEdit) {
          ele.disabled = true;
        }
      });
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, prop, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, prop, [...ids, v.id]);
        } else {
          this[prop.pathIds].push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    toEditSign(row) {
      this.isShowSign = true;
      this.userLabelIds = [];
      if (this.inputParam.userLabelList) {
        // 用户标签
        let userLabelIds = [];
        let cusIds = [];
        this.inputParam.userLabelList.forEach((ele) => {
          cusIds.push(ele.labelId);
        });
        this.businessLabelPathIds.forEach((item) => {
          if (cusIds.includes(item.id)) {
            userLabelIds.push([...item.treeIds]);
          }
        });
        this.userLabelIds = [...userLabelIds];
      }
    },
    isEditTable(row) {
      if (row.id == this.inputParam.id) {
        return false;
      } else {
        return true;
      }
    },
    judgeIsEdit(row, data) {
      // console.log(this.inputParam, data, "00999");
      if (row.id == this.inputParam.id) {
        if (this.inputParam.status == 3) {
          return data == "userLabelList" ? false : true;
        } else if (this.inputParam.status == 2) {
          return data == "phone" ? true : false;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    async save(row) {
      let params = {
        id: row.id,
        realName: this.inputParam.realName || null,
        phone: this.inputParam.phone || null,
        cardType: this.inputParam.cardType || null,
        cardNum: this.inputParam.cardNum || null,
        sex: this.inputParam.sex || null,
        tags:
          this.inputParam.userLabelList.map((a) => a.labelId).join(",") || null,
      };
      let res = await this.$axios.post(modifyContrastUserURL, params);
      if (res.code === 200) {
        this.init();
        for (var i in this.inputParam) {
          this.inputParam[i] = null;
        }
      }
    },
    edit(row) {
      for (var i in this.inputParam) {
        this.inputParam[i] = row[i] || null;
      }
    },
    handleSizeChange(val) {
      this.pages.curPage = 1;
      this.pages.pageSize = val;
      this.getList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pages.curPage = val;
      this.getList();
      console.log(`当前页: ${val}`);
    },
    async getList() {
      this.tableData = [];
      let params = Object.assign(this.searchParam, this.extraParams, {
        curPage: this.pages.curPage,
        pageSize: this.pages.pageSize,
      });
      for (var i in params) {
        if (!params[i]) {
          delete params[i];
        }
      }
      this.isLoading = true;
      let oData = Object.assign(this.postData, {});
      if (this.pages.curPage && this.pages.pageSize) {
        oData = Object.assign(oData, {
          curPage: this.pages.curPage,
          pageSize: this.pages.pageSize,
        });
      }

      let res = await this.$axios({
        url: `${this.tableUrl}`,
        method: this.methods,
        params: params,
        data: oData,
      });
      this.isLoading = false;
      if (res.code === 200) {
        if (res.data.houseList) {
          this.pages.total = res.data.houseList.total || 0;
        } else {
          this.pages.total = res.data.total || 0;
        }
        // this.pages.total = res.data.total || res.data.houseList.total || 0;
        // if (res.data.records.length === 0) {
        //   return;
        // }
        this.tableData = res.data.records || res.data.houseList.records || [];

        this.afterList ? await this.afterList(res.data) : "";
      }
    },
    getRowKey(row) {
      return row.id;
    },
    handleVNodeSlot(slotName, VNode) {
      this.$slots[slotName] = VNode;
    },
    handleSelectionChange(val) {
      console.log(val, "0000");
      this.multipleSelection = val;
      this.$emit("selection-change", val);
    },
    // 固定列对 Table 进行重新布局
    doLayout() {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout();
        //将滚动条置顶
        this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.communityTable {
  height: 100%;
  padding-bottom: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .table {
    flex: 1;
  }
  ::v-deep .el-table {
    .el-button {
      padding: 4px 12px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      background: #0083ff;
      border-radius: 16px;
      line-height: 22px;
      width: auto;
    }
    &::before {
      background: none;
    }
    .opera {
      .el-button {
        color: #3b69f2;
        background: none;
        border: none;
      }
    }
  }
  ::v-deep .v-input {
    width: 110px;
    .el-input {
      width: 100% !important;
      .el-input__inner {
        padding: 0 8px !important;
      }
    }
  }
  .name {
    ::v-deep .v-input {
      width: 90px;
    }
  }
  ::v-deep .v-select {
    width: 60px !important;
    .el-select {
      width: 100% !important;
    }
  }
  ::v-deep .el-table__header-wrapper .el-table__header tr {
    background: #f4faff !important;
  }
  ::v-deep .el-table__header-wrapper th.el-table__cell {
    background: #f4faff !important;
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 25px;
  }
  .cardType {
    ::v-deep .v-select {
      width: 110px !important;
    }
  }
  /deep/ .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  ::v-deep .el-table--scrollable-y .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      box-shadow: inset 0 0 5px #409eff;
      opacity: 0.5;
      background: #409eff;
    }
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 8px;
      height: 10px !important; //修改滚动条宽度
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      box-shadow: inset 0 0 5px #409eff;
      opacity: 0.5;
      background: #409eff;
    }
  }

  .mergeBtn {
    margin: 10px 0;
  }
  .label {
    display: inline-block;
    span {
      display: inline-block;
      padding: 4px 16px;
      border-radius: 4px;
      border: 1px solid #0083ff;
      font-weight: 400;
      font-size: 16px;
      color: #0083ff;
      line-height: 22px;
      margin: 0 4px 4px 0;
    }
  }
  .signDialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.3);
    .box {
      padding: 30px 0;
      position: absolute;
      background: #fff;
      border-radius: 10px;
      width: 50%;
      height: 30%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      ::v-deep .el-cascader {
        width: 250px !important;
      }
      .title {
        text-align: center;
      }
      .signBox {
        display: flex;
        justify-content: center;
      }
      .footer {
        display: flex;
        justify-content: center;
        /deep/ .v-button {
          margin: 0 20px;
        }
      }
    }
  }

  .mergeDialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    .mergeDialogBox {
      padding: 30px 0;
      position: absolute;
      background: #fff;
      border-radius: 10px;
      width: 80%;
      height: 60%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
    }
    .mergeDialogBox-t {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      flex: 1;
      align-items: center;
      .img {
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .mergeDialogBox-l,
      .mergeDialogBox-c,
      .mergeDialogBox-r {
        width: 20%;
        border: 1px dashed #409eff;
        border-radius: 10px;
        height: 80%;
        padding: 20px;
        box-sizing: border-box;
        .list-group {
          width: 100%;
          height: 100%;
        }
        .item {
          display: inline-block;
          padding: 4px 10px;
          border: 1px solid #409eff;
          border-radius: 6px;
          margin: 0 4px 4px 0;
          cursor: pointer;
        }
        .isActive {
          border: 1px solid red;
        }
      }
      .mergeDialogBox-c {
        border: 1px solid #409eff;
        cursor: auto;
      }
    }
    .mergeDialogBox-b {
      display: flex;
      justify-content: center;
      .v-button {
        margin-right: 20px;
      }
    }
  }
}
.vnode-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
