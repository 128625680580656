<template>
  <div class="communityTreat">
    <div class="houseInfoManage-content">
      <div class="section-l">
        <button-tree
          :defaultCheckedKeys="defaultCheckedKeys"
          :default-expanded-keys="defaultExpandedKeys"
          :nodeKey="nodeKey"
          :accordion="true"
          ref="buttonTree"
          class="button-tree"
          lazy
          :defaultProps="treeProps"
          highlightCurrent
          :expand-on-click-node="true"
          @loadData="loadData"
          @check="check"
          @nodeClick="nodeClick"
        />
      </div>
      <div class="section-r">
        <div class="header">
          <div class="header-l">
            <v-input
              v-if="hasChildren == 0"
              label="房号"
              v-model="searchParam.name"
            />
            <v-cascader
              v-loading="businessLabelLoading"
              placeholder="请选择"
              label="标签"
              :width="width"
              v-model="userLabelIds"
              :options="businessSectionOps"
              :props="businessProps"
            />
          </div>
          <div class="header-r">
            <v-button text="搜索" @click="search"></v-button>
            <v-button text="清空" @click="clearNull"></v-button>
          </div>
        </div>
        <div class="section-main scorllBall">
          <div class="title">
            <div class="title-l">
              <span v-if="hasChildren != 0">{{ secondTitle }}</span>
              <div class="titleSign" v-if="hasChildren == 0">
                <div class="importData">只有导入数据</div>
                <div class="bothData">既有导入数据又有注册数据</div>
                <div class="registerData">只有注册数据</div>
                <div class="noData">无数据</div>
              </div>
            </div>
            <div class="title-r" @click="toDetail">
              <div class="img">
                <img src="./img/detail.png" alt="" />
              </div>
              <div class="txt">查看详情</div>
            </div>
          </div>
          <div class="section-main-body" v-loading="isLoading">
            <div
              v-for="(item, index) in list"
              :class="[
                'list',
                returnClass(item),
                { isClick: hasChildren == 0 },
                { isMargin: hasChildren != 0 },
              ]"
              :key="index"
              @click="toRoomDetail(item)"
            >
              <div class="list-box">
                <div class="list-t">{{ item.spaceName }}</div>
                <div class="arrow" v-if="hasChildren == 0">
                  <img src="./img/arrow.png" alt="" />
                </div>
                <div class="list-b">
                  <span>{{ item.peopleNum ? item.peopleNum : 0 }}</span
                  >人
                </div>
              </div>
            </div>
            <div class="nodata" v-if="list.length == 0">
              <img src="@/assets/img/nodata.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      class="my-dialog"
      v-model="isShowCode"
      :isShowClose="true"
      :isShowFooter="false"
    >
      <div class="secondTitle">用户列表</div>
      <div class="my-dialog-table">
        <community-table
          ref="communityTable"
          :tableUrl="tableUrl"
          :searchParam="searchParam"
          :isRoom="isRoom"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import communityTable from "./component/communityTable";
import { getHousingAssetList, getSpaceCardListURL } from "./api";
import {
  getSpaceLinkListURL,
  getContrastUserListURL,
  getLabelTreeListURL,
} from "./api";
import {
  oSexs,
  userStatusMap,
  userStatus,
  oSexsMap,
  cardTypeList,
  cardTypeListMap,
} from "./map";
import { downloadHelper } from "@/utils/func.js";
import ButtonTree from "@/components/bussiness/button-tree/button-tree.vue";
export default {
  name: "communityTreat",
  components: {
    "button-tree": ButtonTree,
    "community-table": communityTable,
  },
  props: {},
  data() {
    return {
      width: 260,
      loading: true,
      type: null,
      isLoading: false,
      nodeKey: "code",
      isShowCode: false,
      defaultCheckedKeys: [], //默认选中的数据
      defaultExpandedKeys: [], //默认的展开的数据
      treeData: [], //房号结构
      iframeUrl: "", //模型地址
      isShow: true,
      tableUrl: getContrastUserListURL,
      searchParam: {
        name: null,
        labels: null,
        spaceId: "",
      },
      businessLabelLoading: false,
      businessLabelIds: [],
      businessSectionOps: [], // 用于显示的数据
      businessLabelPathIds: [], // 添加treeIds的数据
      businessProps: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      id: "",
      isRoom: false,
      headers: [],
      treeProps: {
        // id: "id", // 必须
        // parentId: "parentId",
        // children: "children", // 必须
        label: "text", // 必须
        isLeaf: "isLeaf",
      },

      isLoad: false,
      requestData: {
        auth: 1,
        userId: this.$store.state.app.userInfo.userId,
      },
      userLabelIds: [],
      secondTitle: "",
      list: [],
      hasChildren: "1",
    };
  },
  created() {
    this.getLabelTreeList("", 1, {
      loading: "businessLabelLoading",
      sectionOps: "businessSectionOps",
      pathIds: "businessLabelPathIds",
    });
  },
  watch: {
    userLabelIds: {
      handler(val) {
        this.searchParam.labels = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
    hasChildren: {
      handler(val) {
        console.log("变动=======", val);
        if (val != 0) {
          this.searchParam.name = null;
          console.log("变动======1222=", val);
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    returnClass(item) {
      if (item.colorFlag == 1) {
        return "registerData";
      } else if (item.colorFlag == 2) {
        return "bothData";
      } else if (item.colorFlag == 3) {
        return "importData";
      } else if (item.colorFlag == 4) {
        return "noData";
      }
    },
    toRoomDetail(item) {
      if (this.hasChildren == 0) {
        this.isRoom = true;
        this.searchParam.spaceId = item.spaceId;
        this.isShowCode = true;
        this.$nextTick(() => {
          this.$refs.communityTable.toggleSelection();
          this.$refs.communityTable.getList();
        });
      }
      console.log(item, "909");
    },
    toDetail() {
      this.isShowCode = true;
      this.searchParam.spaceId = JSON.parse(JSON.stringify(this.id));
      this.isRoom = false;
      this.$nextTick(() => {
        this.$refs.communityTable.getList();
      });
    },
    search() {
      this.searchParam.spaceId = JSON.parse(JSON.stringify(this.id));
      this.getPageList();
    },
    clearNull() {
      this.searchParam.name = null;
      this.searchParam.labels = null;
      this.getPageList();
    },
    // 获取标签选项树
    async getLabelTreeList(userId, labelType, prop) {
      this[prop.loading] = true;
      let res = await this.$axios.get(getLabelTreeListURL, {
        params: {
          labelType,
          userId,
          bizLabelId: "", // 商户标签id，商户标签编辑页面的场合必传
        },
      });
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data, prop);
        this[prop.sectionOps] = res.data;
      }
      this[prop.loading] = false;
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, prop, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, prop, [...ids, v.id]);
        } else {
          this[prop.pathIds].push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    //关闭弹窗
    handelClose() {
      this.isShowCode = false;
    },
    download() {
      const suffix = this.codeParams.url.substring(
        this.codeParams.url.lastIndexOf(".")
      );
      downloadHelper.downloadBySrc({
        src: this.codeParams.url,
        rel: "noopener noreferrer",
        target: "_blank",
        fileName: `${this.codeParams.fileName}${suffix}` || `default${suffix}`,
      });
    },

    //获取列表数据
    getPageList() {
      this.isLoading = true;

      let params = {};
      Object.assign(params, this.searchParam);
      if (!this.searchParam.labels) {
        delete params.labels;
      }
      this.$axios
        .get(getSpaceCardListURL, {
          params,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isLoading = false;
            this.list = res.data;
          }
        });
    },

    //编辑
    editData(row) {
      this.$router.push({
        name: "houseInfoManageDetail",
        query: {
          spaceId: row.spaceId,
          path: row.path,
        },
      });
    },
    //懒加载
    loadData(node, resolve) {
      if (node.level == 0) {
        this.getSpaceLinkList(node, resolve);
      } else {
        this.getSpaceLinkList(node, resolve);
      }
    },
    nodeClick(data, node, nodeVue) {
      console.log(data, node, nodeVue, "--");
      this.searchParam.spaceId = data.code;
      this.id = data.code;
      this.hasChildren = data.hasChildren;
      this.secondTitle = data.text;
      this.$nextTick(() => {
        this.getPageList();
      });
    },
    //nodeClick
    check(data, checkedObj) {
      const res = this.$refs.buttonTree.getCheckedKeys();
      const isExist = res.includes(data.code);
      console.log("isExist----->", isExist);
      if (isExist) {
        this.searchParam.parentIds = res.join(",");
      } else {
        if (res.length > 0) {
          this.searchParam.parentIds = res.join(",");
        } else {
          this.searchParam.parentIds = "P1C2A2V1";
        }
      }
      this.$refs.list.getList();
    },

    //获取房屋的楼和楼层模型参数
    getSpaceCut(spaceId) {},

    //获取
    async getSpaceLinkList(node, resolve) {
      console.log(node, 99);
      let parentId;
      let includeManageGroupId = "";
      if (node.level == 0) {
        includeManageGroupId = "yes";
        parentId = "";
      } else {
        parentId = node.data.code;
      }
      const params = {
        specifyCollectionTypes: 6,
        parentId,
        includeManageGroupId,
      };
      const res = await this.$axios.get(getSpaceLinkListURL, { params });
      this.secondTitle = node.data.text;
      this.searchParam.spaceId = parentId;
      this.id = parentId;
      res.data.list.forEach((item) => {
        if (node.level == 0) {
          this.defaultExpandedKeys.push(item.code);
          this.$nextTick(() => {
            this.$refs.buttonTree.setCurrentKey(item.code); // 默认选中节点第一个(高亮效果)
          });
          this.defaultCheckedKeys.push(item.code);
        }
        if (item.hasChildren == 1) {
          item.isLeaf = false;
        } else {
          item.isLeaf = true;
        }
      });
      if (parentId) {
        this.getPageList();
      }
      if (node.data) {
        this.hasChildren = node.data.hasChildren;
      }
      return resolve(res.data.list);
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="less">
.communityTreat {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding: 70px 0 0 0 !important ;
  ::v-deep .el-tree-node__expand-icon {
    font-size: 14px;
    color: #868686;
  }
  ::v-deep .el-tree-node__expand-icon.is-leaf {
    font-size: 0;
  }
  ::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    font-weight: 700;
    font-size: 16px;
    color: #0083ff;
    line-height: 22px;
  }
  .nodata {
    margin: 100px auto;
    text-align: center;
  }

  .houseInfoManage-content {
    height: calc(100% - 20px);
    width: 100%;
    display: flex;
    gap: 26px;
    box-sizing: border-box;
    .section-l {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 34px 19px;
      height: auto;
      width: 338px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      .title {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-bottom: 20px;
      }
      .button-tree {
        flex: 1;
      }
    }
    .section-r {
      padding: 1px 35px 0 0;
      overflow-y: auto;
      height: 100%;
      width: calc(100% - 364px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      .header {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 8px;
        padding: 20px 30px;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        /deep/ .v-control {
          margin-right: 10px;
        }
        .header-l,
        .header-r {
          display: flex;
          align-items: center;
        }
      }
      .section-main {
        background-color: #fff;
        border-radius: 8px;
        flex: 1;
        overflow-y: auto;
        margin-top: 20px;
        padding: 20px 30px;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        .title {
          display: flex;
          justify-content: space-between;
          .title-l {
            display: flex;
            align-items: center;
            span {
              font-weight: 700;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.85);
              line-height: 25px;
            }
            .titleSign {
              display: flex;
              align-items: center;
              font-size: 18px;
              padding-left: 10px;
              .importData {
                margin-left: 20px;
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  width: 20px;
                  height: 20px;
                  background: #0083ff;
                  left: -30px;
                  border-radius: 50%;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
              .registerData {
                position: relative;
                margin: 0 50px 0 0;
                &::before {
                  content: "";
                  position: absolute;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background: #ffa623;
                  left: -30px;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
              .bothData {
                position: relative;
                margin: 0 50px;
                &::before {
                  content: "";
                  position: absolute;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background: #30c991;
                  left: -30px;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
              .noData {
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background: #e6e6e6;
                  left: -30px;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
          }
          .title-r {
            display: flex;
            align-items: center;
            cursor: pointer;
            .txt {
              margin-left: 4px;
              font-weight: 400;
              font-size: 14px;
              color: #0083ff;
              line-height: 20px;
            }
            .img {
              width: 20px;
              height: 22px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .section-main-body {
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          overflow-y: auto;
          gap: 0.8%;
          .list {
            flex-shrink: 0;
            width: 16%;
            height: 190px;
            margin-bottom: 10px;
            background: url("./img/box.png") no-repeat;
            background-size: 100% 100%;
            border-radius: 16px;
            padding: 34px 0 0 26px;
            box-sizing: border-box;
            color: #fff;
            .list-t {
              font-size: 24px;
              line-height: 33px;
            }
            .arrow {
              width: 10px;
              height: 6px;
              margin: 10px 0 25px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .list-b {
              font-size: 16px;
              span {
                font-weight: 700;
                font-size: 28px;
                color: #ffffff;
                line-height: 40px;
                margin-right: 2px;
              }
            }
          }
          .isClick {
            cursor: pointer;
          }
          .partyMem {
            position: relative;
            &::before {
              content: "党员";
              position: absolute;
              top: 0;
              left: 0;
              width: 30px;
              height: 50px;
              color: #fff;
              background: #fe5c5b;
            }
          }
          .importData {
            background: url("./img/importData.png") no-repeat;
            background-size: 100% 100%;
          }
          .registerData {
            background: url("./img/registerData.png") no-repeat;
            background-size: 100% 100%;
          }
          .bothData {
            background: url("./img/bothData.png") no-repeat;
            background-size: 100% 100%;
          }
          .noData {
            background: url("./img/noData.png") no-repeat;
            background-size: 100% 100%;
            color: rgba(0, 0, 0, 0.5);
            span {
              color: rgba(0, 0, 0, 0.5) !important;
            }
          }
          .isMargin {
            .list-b {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1800px) {
  .houseInfoManage-content {
    .section-r {
      .list {
        height: 160px !important;
        .list-t {
          font-size: 18px !important;
        }
        .list-b {
          font-size: 18px !important;
          span {
            font-weight: 700;
            font-size: 16px !important;
            color: #ffffff;
            line-height: 30px !important;
            margin-right: 2px;
          }
        }
      }
      .isMargin {
        .list-b {
          margin-top: 20px !important;
        }
      }
    }
  }
}
/deep/ .el-dialog {
  width: 60% !important;
  height: 60% !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
  .el-dialog__body {
    flex: 1;
    min-height: 0;
    .secondTitle {
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      color: #000000;
      line-height: 25px;
    }
    .my-dialog-table {
      box-sizing: border-box;
      height: 100%;
    }
  }
}
</style>
