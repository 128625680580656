import { mapHelper } from "@/utils/common.js";

const oSexs = [
  {
    value: 2,
    label: "女",
  },
  {
    value: 1,
    label: "男",
  },
];

const { map: oSexsMap, setOps: oSexsOps } = mapHelper.setMap(oSexs);
const cardTypeList = [
  {
    value: "2000",
    label: "居民身份证",
  },
  {
    value: "2002",
    label: "护照",
  },
  {
    value: "2005",
    label: "港澳台同胞来往通行证",
  },
  {
    value: "2099",
    label: "其他",
  },
];

const { map: cardTypeListMap, setOps: cardTypeListOps } =
  mapHelper.setMap(cardTypeList);
const userStatus = [
  {
    value: 1,
    label: "已导入",
  },
  {
    value: 2,
    label: "已注册",
  },
  {
    value: 3,
    label: "已实名认证",
  },
];

const { map: userStatusMap, setOps: userStatusOps } =
  mapHelper.setMap(userStatus);
export {
  oSexs,
  userStatusMap,
  userStatus,
  oSexsMap,
  cardTypeList,
  cardTypeListMap,
};
