//空间结构树接口
const getSpaceLinkListURL = `/gateway/hc-space/space/getSpaceLinkList`;
//用户标签接口
const getLabelTreeListURL = `/gateway/blade-system/label/getLabelTreeList`;
//
//用户标签接口
const getSpaceCardListURL = `/gateway/hc-space/space/getSpaceCardList`;
//底库用户列表查询接口（分页）
const getContrastUserListURL = `/gateway/hc-space/space/getContrastUserList`;
//底库用户编辑保存
const modifyContrastUserURL = `/gateway/hc-space/space/modifyContrastUser`;
//合并用户
const mergeContrastUserURL = `/gateway/hc-space/space/mergeContrastUser`;

export {
  mergeContrastUserURL,
  modifyContrastUserURL,
  getSpaceLinkListURL,
  getContrastUserListURL,
  getSpaceCardListURL,
  getLabelTreeListURL,
};
